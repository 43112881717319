import React from "react";



class Empty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      

      };


    
    
  }
  
    


  render() {
    return (
 <div >
      

      </div>
      
    );
  }
}
export default Empty