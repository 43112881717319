import React from 'react';
import ReactDOM from 'react-dom';



import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';




class Foot2 extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
alert('ok');   
    

   }
render() {
    return (

  
         <div>
		<div>
        <p className="footer-company-about">
					<span>About the company</span><br/>
					For all your petrol station automation needs.  </p>
		</div>
				<div className="footer-icons">
					<a href="#"><i className="fa fa-facebook"></i></a>
					<a href="#"><i className="fa fa-twitter"></i></a>
					<a href="#"><i className="fa fa-instagram"></i></a>
					<a href="#"><i className="fa fa-linkedin"></i></a>
					<a href="#"><i className="fa fa-youtube"></i></a>
				</div>
	</div>


);
}
}
export default Foot2;
