import React from 'react';

import './css/title.css'
function Tittle() {
  return (

<div class="stage1">
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
  <div class="layer1"></div>
 
</div>

    
      
  );
}

export default Tittle;