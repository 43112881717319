import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import img1 from '../assets/panace3.jpeg';
import img2 from '../assets/panace4.png';
import img3 from '../assets/cardreader.jpeg';
import printer from '../assets/printer.jpeg';
import fuel from '../assets/fuel.jpg';
import Mymodal from './mymodal.js'

import './css/my.css'
const handleinfo = () => {  
     ReactDOM.render(
  <React.StrictMode>
    <Mymodal t={1}/>
  </React.StrictMode>,
  document.getElementById('mym')
);
    

     }

function Customer() {
  return (
    <Container>
    <Row className="w3-sans-serif">
    <Col xs="15" md="6">
       <div >

<header class="w3-container ">
  <h2>USSD</h2>

</header>

<div class="w3-container ">
  <p>To pay for fuel by M-Pesa, a motorist just dials *483*403# then enters Station Number e.g. 101, then Nozzle Number e.g. 2 of the product s/he wants, then Amount e.g. 1000 – these can also be done simultaneously e.g. *483*403*101*2*1000#. The buyer will then get a STK push to key in the M-Pesa PIN. By the time the buyer receives the payment notification SMS, the nozzle will be ready to dispense fuel – giving an audio visual signal in the process. The motorist or the attendant then picks the nozzle and pumps.</p>
  </div>



</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img1}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>

 <Row>
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img3} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header >
  <h2>SWYP CARD (KAWAIDA)</h2>

</header>

<div class="w3-container ">
  <p>A motorist can register to have a Kawaida Card. This card further simplifies the process of buying fuel.</p>
  <p>To register the card, the motorist dials the USSD code and selects option 2 on first menu, then enters the details that s/he wants to appear on the receipt and setting a preset amount that the motorist usually buys – Ile ya Kawaida. </p>
  <p>To buy fuel, the motorist taps the card, the motorist immediately gets the STK push with the Kawaida amount, then keys in the M-Pesa PIN.</p>
  <p>Instead of the mode of payment cards, an attendant can also sell for the motorist a desired amount then taps Kawaida Card, this will sent to the motorist an STK push with the amount dispensed and include the buyer’s details on the receipt.</p>
  <p>The motorist can change the Kawaida Amount by dialing *483*403# then selecting option 2.</p>
  </div>


</div> 
</Col>
</Row>

<Row>
    <Col xs="15" md="6">
       <div>

<header class="w3-container ">
  <h2>Guarantee to Fuel 24 hours</h2>

</header>

<div class="w3-container ">
  <p>Kenya is gearing to become a 24hour economy. This will be possible if even deep in the rural villages, the farmers and business people can access fuel to travel whatever time they deem it fit. Self-service sale of fuel is the key.</p>
  </div>


</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img2}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>


 <Row>
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={printer} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header class="w3-container ">
  <h2>Detailed Receipt</h2>

</header>

<div class="w3-container ">
  <p>A motorist can dials *483*403# then select option 3 to save personal details that s/he wishes to be included in the receipt – and this will be automatically printed after every sale.</p>
  
  </div>



</div> 
</Col>
</Row>




</Container>
      
  );
}

export default Customer;
