import React from 'react';
import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown  } from 'reactstrap';
import './css/header.css'
function Demo() {
  return (

<div class="demo">
  <Button > Request Demo</Button>
 
</div>

    
      
  );
}

export default Demo;