import React from 'react';
import Menu from './menu.js'


import './css/header.css'
import { Row, Col} from 'reactstrap';
function Nav() {
  return (

    <div className="nav">
    <Row>
  <Col>
<Menu />
</Col>
</Row>
</div>

    
      
  );
}

export default Nav;