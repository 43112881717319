import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Marquee from "react-fast-marquee";
import Head from './head.js'
import Head1 from './head1.js'
import Demo from './demo.js'
import Con from './con.js'
import Content from './content.js'
import Footer from './footer.js'
import Tittle from './tittle.js'
import Summary from './summary.js'
import Dial from './dial.js'
import '../App.css';
import './css/footer.css'
import './css/my.css'
import background from "./img/back1.png";


function Mbody() {
  return (
    
      <div>
      
      <mbody className="App-body">
      <Content />
      </mbody>
      </div>
      
  );
}

export default Mbody;
