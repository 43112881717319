import React from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import validator from 'validator'
import './css/modal.css';
import './css/button.css'
import './css/footer.css'
import './css/my.css'
import Empty from './empty.js'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';


class Mymodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      modal: false,
      modal1: true,
      modal2: false,
      email: '',
      vemail: false,
      mobno: '',
      vmobno: false,
      email: '',
      mmessage: 'More Information',
      vmessage: '',
      vmessagemobno: '',
      error1: false
     


      };


    
     this.handleModal = this.handleModal.bind(this);
    
   this.handleemail=this.handleemail.bind(this)
   this.handlemobno=this.handlemobno.bind(this)
   this.handlesend=this.handlesend.bind(this)

    
    
  }
  componentDidCatch(error, info) {    // Display fallback UI    this.setState({ hasError: true });    // You can also log the error to an error reporting service    logErrorToMyService(error, info);  }
this.setState({error1: true})
}
  handleemail(event){
    this.setState({email: event.target.value})
    if (validator.isEmail(event.target.value)) {
     this.setState({vemail: true, vmessage: ''})
    } else {
      this.setState({vemail: false, vmessage: 'Please enter Valid email'})
    }
  }

  handlemobno(value){
    if (this.state.error1){
      
    }
    else
    {
   
    
    
    if (typeof(value) !== 'undefined' && value != null){
      this.setState({mobno: value})
      //validate phone
      if (isValidPhoneNumber(value)){
        this.setState({vmessagemobno: '', vmobno: true})
        
      }
      else{
        this.setState({vmessagemobno: 'Enter Valid mobile no.', vmobno: false})
      }
      }
    }
  }

  

  handlesend(){
    var jarray=[]
    

      jarray.push({email: this.state.email, mobno: this.state.mobno, mmessage: this.state.mmessage});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.swyp.co.ke/admin/feedback.php';

    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
   alert ('Thank You for your Inquiry, You will get a response soon')
   msta.setState({email: '', mobno: '', mmessage: ''})
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
    msta.setState({modal: false})
   ReactDOM.render(
  <React.StrictMode>
    <Empty />
  </React.StrictMode>,
  document.getElementById('mym')
  );
  });
  }
  componentDidMount() {

  this.interval = setTimeout(() => this.setState({ modal: true}), this.props.t);
}


 handleModal(){
  this.setState({modal: false})
   ReactDOM.render(
  <React.StrictMode>
    <Empty />
  </React.StrictMode>,
  document.getElementById('mym')
  );
 }

    


  render() {
    return (
 <div className="ff">
      <Form onSubmit={this.handleSubmit}>
      
        <div>
      <Modal isOpen={this.state.modal} toggle={this.toggle} >
        <ModalHeader toggle={this.toggle}>For More information Enter Details below</ModalHeader>
        <ModalBody>
        <Form>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.email} type="email" onChange={this.handleemail}/>
             
          </InputGroup>
          <p className="w3-text-red">{this.state.vmessage}</p>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            
           
             <PhoneInput
             placeholder="Phone Number"
             defaultCountry="KE"
            value={this.state.mobno}
            onChange={mobno => this.handlemobno(mobno)}
            className="mobno"
              />
             
          </InputGroup>
          <p className="w3-text-red">{this.state.vmessagemobno}</p>
          </FormGroup>
          
        </Form>
        </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={this.handlesend} className="pushable">
          <span className="shadow"></span>
          <span className="edge"></span>
          <span className="front">
            More Info
          </span>
        </Button>
         
          <Button color="secondary" onClick={this.handleModal} className="pushable">
          <span className="shadow"></span>
          <span className="edge"></span>
          <span className="front">
            Cancel
          </span>
          </Button>

        </ModalFooter>
      </Modal>
    </div>

      </Form>

      </div>
      
    );
  }
}
export default Mymodal 