import React from 'react';
import ReactDOM from 'react-dom';
import blog1 from '../assets/blog1.jpg';
import blog2 from '../assets/blog2.jpg';
import { Container } from 'reactstrap';
import './css/my.css'

function Blog() {

  return (

<div class="w3-sans-serif w3-margin-top polaroid1">
<h2>Why we Need Self Service Petrol Stations in Kenya</h2>
<p>How many times have you driven into a petrol station and you are kept waiting for a dozen minutes as the attendant dashes back and forth among the diesel, petrol, and even kerosene pumps? How many times have you had to drive away without having refuelled?</p>
<p>This frustration doesn’t apply to car owners alone. I believe it sounds too familiar with motorcycle (boda boda) and tuktuk riders as well.</p>
<p>Another question:</p>
<p>Have you ever rushed to the nearby fuel station only to find that it’s yet to open for business or sadly, they’ve closed shop at 9:00PM. Sounds too frequent, right? How hopeless does it feel? As if you should only drive your vehicle between 8:00AM and 9:00PM. Another curfew for what?</p>
<p>Sample this:</p>
<p>Just last month, I visited my family in Kisumu and on one eventful dawn, I saw a motorcyclist steer into a petrol station, and then he immediately left, while throwing tantrums, because fuel attendants had not yet arrived. I had a firsthand experience soon after when I needed urgent medical attention at midnight in the nearest city, Kisumu, yet all the petrol stations in my neighbourhood had been shut down.</p>
<p>Cutting a long story short, it’s time we have self-service petrol stations in Kenya because they have many hitches and headaches to solve.</p>
<p>Swyp is our new innovation for automating retail fuel sales and management and it will have some of the following benefits to a fuel buyer.</p>
<h3>1. You don’t have to be delayed by overwhelmed, lazy, or defiant pump attendants</h3>
<p>I've noticed for a long time that petrol stations in our towns and neighbourhoods like South B, Kasarani, Githurai, etc., do understaff their businesses, obviously to cut labour costs. And the attendants are most of the time overwhelmed by customers since these fuel stations sell different petroleum products to motorists and residents. </p>
<p>So sometimes as a car or motorbike owner, you can ride into a petrol station but you have to wait since the attendant is at another pump selling kerosene. You could have saved precious time with a self-service system. The same thinking applies to the kerosene buyers- they can also serve themselves.</p>
<p>Some attendants may also be lazy and tend to drag themselves, perhaps because they’re protesting their lean salaries.</p>
<h3>2. You need to be sure that what you pay for is what you get.</h3>
<p>By any chance, do you doubt the number of litres of fuel that the attendant says he or she has pumped into your fuel tank? This goes without saying, but Sywp is here to sort that out. </p>
<p>With a fuel self-service system, you get to hold the pump nozzle and you can shake it until you ensure that it is completely emptied before ejecting it from your fuel cap.</p>
<img src={blog1}  className="img2"/>
<h3>3. You should be free to buy whatever amount of fuel you want</h3>
<p>I won’t go over and over about how fuel has become a luxury, but as a boda boda rider, there are many times when you want to buy whatever drops of fuel will be worth Ksh50, ferry two customers and you're done for the day. </p>
<p>Your car, tuktuk, or boda boda could also have developed a fault and you want to refuel with an amount of petrol or diesel just enough to make the machine huff and puff to the nearest mechanic. </p>
<p>Without forgetting that you have that cooking stove at home for which you only purchase Ksh40 worth of kerosene because times are hard.</p>
<p>There should be no problem at all with all of the above.</p>
<p>But we are living in a materialistic world where if you are buying something worth 20/= or 50=, yet there others buying more than 1,000/= worth of the same commodity, you are seen as a nuisance. So some attendants may become unenthusiastic when they see you pulling coins from your pocket for yet another time. It seems they develop the attitude that "huyu aninusumbua tu na najua anataka diesel ya mbao ”.</p>
<p>With Swyp, you will be able to drive, ride, or walk in, serve yourself whatever type and volume of fuel you can afford and speed away. Without shame or guilt.</p>
<img src={blog2}  className="img2"/>
<h3>4. Petrol or diesel 24/7</h3>
<p>Now you don’t have to stop going for that night out just because you can’t find fuel anywhere in your hood. Or to let your ill loved one spend the night at home in pain as you wonder how your dry fuel tank will cough to the nearest medical centre. </p>
<p>Swyp automates fuel dispensing, thus complementing pump attendants. With this system, you are guaranteed to buy fuel any time of day or night, whether there’s an attendant or not.</p>
<p>So what are you waiting for?</p>
<p>Let’s embrace <em>self-service petrol stations in Kenya</em> and catch up with the advanced economies.</p>

 
</div>

    
      
  );
}

export default Blog;
