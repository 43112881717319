import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import img1 from '../assets/nafta.jpeg';
import img2 from '../assets/panace4.png';
import img3 from '../assets/profit.png';
import './css/my.css'

function Info() {
  return (
    <div className="w3-sans-serif">
       <div className="polaroid1 w3-sans-serif  w3-margin">

<header class="w3-container ">
  <h2>About Us:</h2>

</header>

<div class="w3-container ">
  <p>Panacea Innovative Solutions Co. Ltd. is a Kenya youth-led start-up, which has dedicated its time, resources and innovation prowess, to solve long-standing challenges widely affecting Kenyans by leveraging on engineering principles and technology.</p>
  </div>

<footer class="w3-container">
  <p></p>
</footer>

</div> 

<div class="polaroid1  w3-margin">

<header class="w3-container ">
  <h2>Vision:</h2>

</header>

<div class="w3-container ">
  <p>To be the leading innovative solutions provider in East Africa, providing the best and affordable technology to address societal, government and manufacturing industry's challenges - leading to a vibrant 24 hour economy nation.</p>
  </div>

<footer class="w3-container">
  <p></p>
</footer>

</div> 

<div class="polaroid1  w3-margin">

<header class="w3-container ">
  <h2>Mission:</h2>

</header>

<div class="w3-container ">
  <ul>
  <li>To identify societal needs and come up with technological meaasures to address them</li>
  <li>To provide automation services aimed at minimizing business operation costs.</li>
  <li>To provide consultative service to the government on addressing long-standing problems</li>
  <li>To improve on safety, security and sanity on the country's roads</li>
  <li>To ease service delivery in energy sector</li>
  </ul>
  </div>

<footer class="w3-container">
  <p></p>
</footer>

</div>  


</div>
      
  );
}

export default Info;
