import React from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'reactstrap';
import './css/my.css'

function Blog() {

  return (

<div class="w3-sans-serif w3-margin-top polaroid1">
<h2>SWYP FAQS</h2>
<p>1. What do I need to have Swyp in my petrol station?</p>
<p>A petrol station will need to install the PTS Forecourt Controller in order to use Swyp. If the station doesn’t already have the PTS, then we can install it. </p>
<p>2. How long does Swyp USSD payment takes to preset a pump?</p>
<p>By the time you receive payment confirmation SMS, the pump will have preset.</p>
<p>3. What if I pay to the wrong product nozzle?</p>
<p>If you pay to a wrong nozzle, then just lift the nozzle and return. It will consider that a zero sales – the whole amount will be reversed.</p>
<p>4. Should I pay for fuel before I arrive at the station?</p>
<p>It is advisable to pay for fuel after you have parked beside the pump – after the preceding motorist has been served.</p>
<p>5.What if my tank gets full before the full amount paid is dispensed?</p>
<p>In that case, the balance will be sent back to you.</p>
<p>6. What if I want full tank?</p>
<p>Pay slightly above the amount expected to fill the tank – the balance will be sent back to you.</p>

</div>

    
      
  );
}

export default Blog;
