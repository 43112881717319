import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Contact from './contacts'
import Home from './home'
import Info from './info'
import Station from './station'
import Customer from './customer'
import Contact2 from './contact2'
import Career from './career'
import Spin from './spin'
import Mbody from './mbody'
import Blog from './blog'
import Faq from './faq'
import './css/my.css'

import { Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown  } from 'reactstrap';


const Menu = (props) => {

  const [collapsed, setCollapsed] = useState(true);
  const [showmenu, setshowmenu] = useState(false);
  const [homecss, sethomecss] = useState('mn');
  const [aboutcss, setaboutcss] = useState('mn');
  const [solutioncss, setsolutioncss] = useState('mn');
  const [resourcecss, setresourcecss] = useState('mn');
  const [contactcss, setcontactcss] = useState('mn');
  const [democss, setdemocss] = useState('mn');
  const [careercss, setcareercss] = useState('mn');

  const toggleNavbar = () => setCollapsed(!collapsed);
  const toggleNavbar1 = () => setCollapsed(!collapsed);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [dropdownOpen4, setDropdownOpen4] = useState(false);
  const [dropdownOpen5, setDropdownOpen5] = useState(false);
  const [groupn, setGroupn] = useState("")

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggle1 = () => setDropdownOpen1(prevState => !prevState);
  const toggle2 = () => setDropdownOpen2(prevState => !prevState);
  const toggle3 = () => setDropdownOpen3(prevState => !prevState);
  const toggle4 = () => setDropdownOpen4(prevState => !prevState);
  const toggle5 = () => setDropdownOpen5(prevState => !prevState);
  const [lastClicked, setLastClicked] = useState(null);
const message1 = (e,ss) => {
e.preventDefault();
 alert(ss);
}
 
 const handlelogout = () => {  
    window.location.reload();
    

     }

const handleClick = (e,step) => { 


  switch(step){
    case 1:
    sethomecss("mns")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
       ReactDOM.render(
  <React.StrictMode>
    <Spin r={Home}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);

      
      break
       case 2:
       sethomecss("mn")
    setaboutcss("mns")
    setsolutioncss("mn")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
        ReactDOM.render(
  <React.StrictMode>
    <Spin r={Info}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
    
      
      break;
    case 3:
    sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mns")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
      ReactDOM.render(
  <React.StrictMode>
  <Spin r={Station}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;
      case 4:
      sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mns")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
      ReactDOM.render(
  <React.StrictMode>
  <Spin r={Customer}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;

      case 5:
      sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mn")
    setcareercss("mns")
    setcontactcss("mn")
    setdemocss("mn")
      ReactDOM.render(
  <React.StrictMode>
  <Spin r={Career}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;

       case 6:
       sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mns")
    setdemocss("mn")
      ReactDOM.render(
  <React.StrictMode>
  <Spin r={Contact}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;
       case 7:
       sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mn")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mns")
      ReactDOM.render(
  <React.StrictMode>
  <Spin r={Contact2}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;
      
       case 12:
       sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mns")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
      ReactDOM.render(
      
  <React.StrictMode>
  <Spin r={Blog}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;
       case 13:
       sethomecss("mn")
    setaboutcss("mn")
    setsolutioncss("mn")
    setresourcecss("mns")
    setcareercss("mn")
    setcontactcss("mn")
    setdemocss("mn")
      ReactDOM.render(
      
  <React.StrictMode>
  <Spin r={Faq}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
      break;
    
      
  }
    
   }


  return (
      <Navbar   ligth expand="md" className="w3-text-black pointer">
        
        <NavbarToggler onClick={toggleNavbar} className="mr-2 " style={{ backgroundColor: '#378248' }} />
    
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar >
          <NavItem className="w3-text-black">
              <NavLink onClick={e=>handleClick(e,1)} className={homecss}>HOME</NavLink>
            </NavItem>
            <NavItem style={{ marginLeft: 10 }}>
              <NavLink onClick={e=>handleClick(e,2)} className={aboutcss}>ABOUT US</NavLink>
            </NavItem>

            
           
      <Dropdown isOpen={dropdownOpen2} toggle={toggle2} className={solutioncss}>
      
      <DropdownToggle nav  caret className="d-flex align-items-center" style={{ marginLeft: 10 }} >
        SOLUTIONS
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,3)} className="mn">PETROL STATION</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,4)} className="mn">MOTORIST</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>

    <Dropdown isOpen={dropdownOpen} toggle={toggle} className= {resourcecss}>
      
      <DropdownToggle nav  caret className="d-flex align-items-center" style={{ marginLeft: 10 }}>
        RESOURCES
      </DropdownToggle>
      <DropdownMenu>
        
        <DropdownItem onClick={e=>handleClick(e,12)} className="mn">BLOG</DropdownItem>
        <DropdownItem onClick={e=>handleClick(e,13)} className="mn">FAQS</DropdownItem>
    
      </DropdownMenu>
    </Dropdown>
    <NavItem style={{ marginLeft: 10 }}>
              <NavLink onClick={e=>handleClick(e,5)} className={careercss}>CAREERS</NavLink>
            </NavItem>
      
         <NavItem style={{ marginLeft: 10 }}>
              <NavLink onClick={e=>handleClick(e,6)} className={contactcss}>CONTACTS</NavLink>
            </NavItem>

            <NavItem style={{ marginLeft: 11 }}>
              <NavLink onClick={e=>handleClick(e,7)} className={democss}>REQUEST DEMO</NavLink>
            </NavItem>
     
    
    
    


          </Nav>
        </Collapse>
      </Navbar>
   
  );
}

export default Menu;
