import React from 'react';
import ReactDOM from 'react-dom';
import './css/footer.css'



import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';




class Foot1 extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
alert('ok');   
    

   }
render() {
    return (

  
         <div >
        <div>
					<i className="fa fa-map-marker"></i>
					  <p><span>P.O.Box 63019 -00200,<br/>
						 Nairobi,</span><br/>
						Kenya.</p>
				</div>

				<div>
					<i className="fa fa-phone"></i>
					<p>+254 716796187</p>
				</div>
				<div>
					<i className="fa fa-envelope"></i>
					<p><a href="mailto:info@swyp.co.ke">info@swyp.co.ke</a></p>
				</div>
	</div>


);
}
}
export default Foot1;
