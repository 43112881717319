import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import img1 from '../assets/panace3.jpeg';
import img2 from '../assets/inventory.jpeg';
import tag from '../assets/tag.jpeg';
import printer from '../assets/printer.jpeg';
import discount from '../assets/discount.jpeg';
import img3 from '../assets/profit.png';
import Mymodal from './mymodal.js'

import './css/my.css'
const handleinfo = () => {  
     ReactDOM.render(
  <React.StrictMode>
    <Mymodal t={1}/>
  </React.StrictMode>,
  document.getElementById('mym')
);
    

     }

function Station() {
  return (
    <Container className="w3-sans-serif">
    <Row>
    <Col xs="15" md="6">
       <div >

<header class="w3-container ">
  <h2>USSD Initiated Payments</h2>

</header>

<div class="w3-container ">
  <p>With USSD initiated payments, Swyp channels the M-Pesa payment direct to a particular nozzle, presets and unlocks the nozzle – giving an audio visual signal in the process. The attendants don’t need to ask how much a motorist needs, or hold the motorist hostage after pumping to confirm payment and record Reference Number thus hastening operations. This also enables self-service sales capabilities.</p>
  </div>



</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img1}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>

 <Row>
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img2} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header >
  <h2>Capture all Sales Opportunity</h2>

</header>

<div class="w3-container ">
  <p>With self-service as an option of making sales, petrol stations have the confidence to continue selling whether an attendant is out for lunch or on sick leave. A petrol station will also continue to operate cost-effectively at night.</p>
  </div>


</div> 
</Col>
</Row>

<Row>
    <Col xs="15" md="6">
       <div>

<header class="w3-container ">
  <h2>Unique Attendant Tagging</h2>

</header>

<div class="w3-container ">
  <p>Swyp not only uses attendant tags to identify the attendant who made sales on a particular nozzle, but also to indicate the mode of payment for each sale.</p>
  </div>


</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={tag}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>


 <Row>
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img2} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header class="w3-container ">
  <h2>Intelligent Sales Reconciliation</h2>

</header>

<div class="w3-container ">
  <p>Swyp makes easy work of handling all the three common modes of payment in Kenya.</p>
  <p>Swyp generates an easy to comprehend sales report that simplifies sales reconciliation. The simple format classifies all sales by the mode of payment, the attendant who made it, and by the nozzle from which it was made. Swyp also stacks M-Pesa Till payment report among the sales reports so as to ease reconciliation.</p>
  </div>



</div> 
</Col>
</Row>

<Row>
    <Col xs="15" md="6">
       <div>

<header class="w3-container ">
  <h2>Automated Receipt Printing</h2>

</header>

<div class="w3-container ">
  <p>Swyp automatically prints a receipt after every sale – indicating buyers’ preferred details – thus reducing workload on staff.</p>
  </div>



</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={printer}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>


<Row>
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={discount} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div>

<header class="w3-container ">
  <h2>Set Discount</h2>

</header>

<div class="w3-container ">
  <p>The manager can also set discounts by the phone that is automatically implemented if a buyer pays by USSD. This ends fraud committed by station operators who steal money and claim they issued discounts.</p>
  </div>


</div> 
</Col>
</Row>
 
</Container>
      
  );
}

export default Station;
