import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import validator from 'validator'
import './css/footer.css';
import './css/button.css';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import './css/my.css'



class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      vemail: false,
      mobno: '',
      vmobno: false,
      email: '',
      mmessage: '',
      vmessage: '',
      vmessagemobno: '',
      error1: false
      };
   
   this.handleemail=this.handleemail.bind(this)
   this.handlemobno=this.handlemobno.bind(this)
   this.handlemmessage=this.handlemmessage.bind(this)
   this.handlesend=this.handlesend.bind(this)

    
    
  }

componentDidCatch(error, info) {    // Display fallback UI    this.setState({ hasError: true });    // You can also log the error to an error reporting service    logErrorToMyService(error, info);  }

this.setState({error1: true})
}

  handleemail(event){

    this.setState({email: event.target.value})
    if (validator.isEmail(event.target.value)) {
     this.setState({vemail: true, vmessage: ''})
    } else {
      this.setState({vemail: false, vmessage: 'Please enter Valid email'})
    }
  }

  handlemobno(value){
    if (this.state.error1){
      
    }
    else
    {
   
    
    
    if (typeof(value) !== 'undefined' && value != null){
      this.setState({mobno: value})
      //validate phone
      if (isValidPhoneNumber(value)){
        this.setState({vmessagemobno: '', vmobno: true})
      
      }
      else{
        this.setState({vmessagemobno: 'Enter Valid mobile no.', vmobno: false})
      }
      }
    }
  
  }

  handlemmessage(event){
    this.setState({mmessage: event.target.value})

  }

  handlesend(){
    if (this.state.vmobno==true && this.state.vemail==true){
    var jarray=[]
    

      jarray.push({email: this.state.email, mobno: this.state.mobno, mmessage: this.state.mmessage});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.swyp.co.ke/admin/feedback.php';

    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
   alert ('Thank You for your Inquiry, You will get a response soon')
   msta.setState({email: '', mobno: '', mmessage: ''})
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
}
else{
  alert ('Invalid Entry')
}
  }


  render(){

return (
	<div className="w3-sans-serif">
       <div class="polaroid1  w3-margin">

<header class="w3-container ">
  <h2>CAREER INFORMATION</h2>
  <p>Please send your cv and cover letter to careers@swyp.co.ke for future consideration</p>

</header>



<footer class="w3-container">
 
</footer>

</div> 

 
<div id="mt">
</div>

</div> 
);
}
}
export default Careers; 	
