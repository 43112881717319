import React from 'react';
import ReactDOM from 'react-dom';



import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';




class Foot extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
alert('ok');   
    

   }
render() {
    return (

  
         <div>
		<p className="footer-links">
					<a href="#">Home</a>
					|
					<a href="#">Blog</a>
					|
					<a href="#">About</a>
					|
					<a href="#">Contact</a>
				</p>

				
	</div>


);
}
}
export default Foot;
