import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import img1 from '../assets/pts.jpg';
import img2 from '../assets/fuel.jpg';
import profit from '../assets/profit.png';
import reduced from '../assets/reduced.jpeg'
import fraud from '../assets/fraud.jpeg'
import control from '../assets/control.jpeg'
import Mymodal from './mymodal.js'

import './css/my.css'
const handleinfo = () => {  
     ReactDOM.render(
  <React.StrictMode>
    <Mymodal t={1}/>
  </React.StrictMode>,
  document.getElementById('mym')
);
    

     }

function Home() {
  return (
    <div class="w3-sans-serif">
    <Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div >

<header class="test1">
  <h2>PETROL STATION AUTOMATION</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Swyp works with the versatile PTS Forecourt Controller that works with many kinds of pumps in the local petrol stations. PTS is connected to all pumps in the petrol station – to get information of every transaction sold by the pump. PTS is also connected to automatic tank gauge which measures the level of fuel in the underground tanks at higher frequency than dip sticks.</p>
  </div>



</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img1}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>

 <Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={img2} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header class="test1">
  <h2>KEEP ON SELLING</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Swyp introduces self-service sales accepting payment by mobile money that is possible by even a feature phone. With this a petrol station is guaranteed to make sales whether there’s an attendant or not, both day and night.</p>
  </div>


</div> 
</Col>
</Row>

<Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div>

<header class="test1">
  <h2 >REDUCED WORKLOAD ON STAFF</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Swyp automatically prints receipts, generates detailed sales reports and changes shift thus letting the staff to concentrate on customer service.</p>
  </div>


</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={reduced}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>


 <Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={fraud} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div >

<header class="test1">
  <h2>TRANSPARENCY AND FRAUD-FREE BUSINESS</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Swyp saves all transactions on a local memory and avails the same to an online platform from which it can be accessed by the station owner. Swyp monitors sales by automatically generating comparisons of the sales and the totals fetched from the pumps.</p>
  </div>



</div> 
</Col>
</Row>

<Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div>

<header class="test1">
  <h2 >CONTROL IN YOUR HAND</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Swyp restores control of the petrol station operation back to the manager or owner by simplifying control of some previously technical operations such as setting prices to a simple operation done by the phone.</p>
  </div>



</div> 
</Col>
<Col xs="15" md="6">

 <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={control}  className="img1"/>
  
  </div>



</div> 
</Col>
</Row>


<Row className="w3-margin-top">
    <Col xs="15" md="6">
       <div class="polaroid1  w3-margin">


<div class="w3-container ">
<img src={profit} className="img1"/>
  </div>

</div> 
</Col>
<Col xs="15" md="6">

 <div>

<header class="test1">
  <h2 >REDUCED OPERATION COST</h2>

</header>

<div class="w3-container ">
  <p class="test1b">Many petrol stations are forced to shut down at night because of high operation cost compared to sales. With Swyp – you can continue to serve motorists at night with minimal staff.</p>
  </div>


</div> 
</Col>
</Row>
 
</div>
      
  );
}

export default Home;
