import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Nav from './nav.js'
import img1 from './img/panace1.png';
import img2 from './img/swyp1.png';
import logo from './img/logo.png';
import './css/my.css'

function Head() {
  return (
    <Row>
      <Col xs="15" md="1" className="plogo">
      <img src={logo} alt="" 
    className="mlogo"
        />
      </Col>
      <Col xs="15" md="2" className="w3-text-red">

        <h1 className="test3"><span className="test5">S</span><span className="test4">wyp</span></h1>
        </Col>
        <Col xs="15" md="8" className="App-menu">
        <Nav />
        </Col>
      </Row>
    
      
  );
}

export default Head;