import React from 'react';
import ReactDOM from 'react-dom';
import { SocialIcon } from 'react-social-icons';
import Foot from './Foot';
import Foot1 from './Foot1';
import Foot2 from './Foot2';
import Info from './info'
import Station from './station'
import Customer from './customer'
import Contact2 from './contact2'
import Career from './career'
import Spin from './spin'
import Mbody from './mbody.js'
import Blog from './blog'
import Faq from './faq'
import './css/w3.css';
import './css/my.css';
import './css/w3-2018.css';
import './css/bootstrap.min.css';
import './css/footer.css'


import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';

class Footer extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
    this.handleabout = this.handleabout.bind(this); 
    this.handledemo = this.handledemo.bind(this); 
    this.handlestation = this.handlestation.bind(this); 
    this.handlemotorist = this.handlemotorist.bind(this); 
    this.handlefaqs = this.handlefaqs.bind(this); 
   }

handleClick() {    
    

   }
   
   handleabout(){
   	ReactDOM.render(
  <React.StrictMode>
    <Spin r={Info}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
   } 
   handledemo(){
   	 ReactDOM.render(
  <React.StrictMode>
  <Spin r={Contact2}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
   }
   handlestation(){
   	ReactDOM.render(
  <React.StrictMode>
  <Spin r={Station}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
   } 
   handlemotorist(){
   	 ReactDOM.render(
  <React.StrictMode>
  <Spin r={Customer}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
   } 
   handlefaqs(){
   ReactDOM.render(   
  <React.StrictMode>
  <Spin r={Faq}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
   } 
render() {
    return (

  <footer  className="w3-sans-serif">
  <h2 className="footer-company-name">Panacea Innovative Solutions Co. Ltd</h2>

     <Row >
        
          <Col xs={"15"} md={"3"}  >
            <p>About us</p>
            <p class="test1b" style={{fontsize: 8}}>Panacea Innovative Solutions Co. Ltd. is a Kenya youth-led start-up, which has dedicated its time, resources and innovation prowess, to solve long-standing challenges widely affecting Kenyans by leveraging on engineering principles and technology.</p>
      

          </Col>
          
          <Col xs={"15"} md={"3"}>
     
             <Foot1 />
            
  
          </Col>
    <Col xs={"15"} md={"3"}>
     
             <p >Quick Links</p>
             <p className="pointer fmn" onClick={this.handleabout}>about</p>
             <p className="pointer fmn" onClick={this.handledemo}>Request Demo</p>
             <p className="pointer fmn" onClick={this.handlestation}>Station</p>
             <p className="pointer fmn" onClick={this.handlemotorist}>Motorist</p>
             <p className="pointer fmn" onClick={this.handlefaqs}>Faqs</p>
            
  
          </Col>
          <Col xs={"15"} md={"3"}>
     
             <p>Follow us on</p>
             <SocialIcon url="https://linkedin.com"  style={{ height: 25, width: 25, margin: 5 }} />
             <SocialIcon url="https://web.facebook.com/" style={{ height: 25, width: 25, margin: 5 }}/>
             <SocialIcon url="https://twitter.com/" style={{ height: 25, width: 25, margin: 5 }}/>
             <SocialIcon url="https://telegram.com/" style={{ height: 25, width: 25, margin: 5 }}/>
             <SocialIcon url="https://whatsapp.com/" style={{ height: 25, width: 25, margin: 5 }}/>
             
            
  
          </Col>
          
        </Row>
    </footer>

);
}
}
export default Footer;
