import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import img1 from './img/panace1.png';
import Side from './side.js'
import Side1 from './side1.js'
import Side2 from './side2.js'

import Home from './home.js'

function Content() {
  return (
    <Container id="home">
    <Home />
    </Container>
      
  );
}

export default Content;