import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Nav from './nav.js'
import img2 from './img/swyp1.png';
import logo from './img/logo.png';
import img1 from '../assets/mob2.png';
import step2 from '../assets/mob3.png';
import step3 from '../assets/mob4.png';
import step4 from '../assets/mob5.png';
import './css/my.css'

function Dial() {
  return (
  <div className="sum w3-sans-serif w3-margin border-top">
  <h3>HOW TO FUEL WITH SWYP</h3>
    <Row >
      <Col xs="15" md="3">
      <div className="w3-container">
       <div className="w3-card dial">
       <p className="w3-padding">Park beside a pump with desired prodduct and switch off engine. while the nozzle is still down, dial *483*403# and enter STATION NUMBER.</p>
       <footer className="w3-container w3-display-bottommiddle">
       <img src={img1}  className="img3 w3-margin"/>
        </footer>
      </div> 
      </div>
      </Col>
      <Col xs="15" md="3" >
        <div className="w3-container">
       <div className="w3-card dial">
       <p className="w3-padding">Enter the NOZZLE NUMBER</p>
       <footer className="w3-container w3-display-bottommiddle">
       <img src={step2}  className="img3 w3-margin"/>
        </footer>
      </div> 
      </div>
        </Col>
        <Col xs="15" md="3">
          <div className="w3-container">
       <div className="w3-card-4 dial">
       <p className="w3-padding">Enter the AMOUNT</p>
       <footer className="w3-container w3-display-bottommiddle">
       <img src={step3}  className="img3 w3-margin"/>
       </footer>
        
      </div> 
      </div>
        </Col>
        <Col xs="15" md="3">
          <div className="w3-container">
       <div className="w3-card-4 dial">
       <p className="w3-padding"> Enter M-Pesa PIN then pick the nozzle after a green light and beep by the cardreader. Collect a receipt after transaction.</p>
       <footer className="w3-container w3-display-bottommiddle">
       <img src={step4}  className="img3 w3-margin"/>
       </footer>
        
      </div> 
      </div>
        </Col>
      </Row>
      </div>
    
      
  );
}

export default Dial;
