import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import validator from 'validator'
import './css/footer.css';
import './css/button.css';
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, InputGroup, InputGroupText, InputGroupAddon, Input, Form, FormGroup, Label, Row, Col} from 'reactstrap';
import './css/my.css'



class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      vemail: false,
      mobno: '',
      vmobno: false,
      email: '',
      mmessage: '',
      vmessage: '',
      vmessagemobno: '',
      error1: false
      };
   
   this.handleemail=this.handleemail.bind(this)
   this.handlemobno=this.handlemobno.bind(this)
   this.handlemmessage=this.handlemmessage.bind(this)
   this.handlesend=this.handlesend.bind(this)

    
    
  }

componentDidCatch(error, info) {    // Display fallback UI    this.setState({ hasError: true });    // You can also log the error to an error reporting service    logErrorToMyService(error, info);  }

this.setState({error1: true})
}

  handleemail(event){

    this.setState({email: event.target.value})
    if (validator.isEmail(event.target.value)) {
     this.setState({vemail: true, vmessage: ''})
    } else {
      this.setState({vemail: false, vmessage: 'Please enter Valid email'})
    }
  }

  handlemobno(value){
    if (this.state.error1){
      
    }
    else
    {
   
    
    
    if (typeof(value) !== 'undefined' && value != null){
      this.setState({mobno: value})
      //validate phone
      if (isValidPhoneNumber(value)){
        this.setState({vmessagemobno: '', vmobno: true})
      
      }
      else{
        this.setState({vmessagemobno: 'Enter Valid mobile no.', vmobno: false})
      }
      }
    }
  
  }

  handlemmessage(event){
    this.setState({mmessage: event.target.value})

  }

  handlesend(){
    if (this.state.vmobno==true && this.state.vemail==true){
    var jarray=[]
    

      jarray.push({email: this.state.email, mobno: this.state.mobno, mmessage: this.state.mmessage});
      


    const mydata = JSON.stringify(jarray);
    

    //send data online
     const url = 'https://www.swyp.co.ke/admin/feedback.php';

    const msta=this;
   axios.post(url, mydata, {headers:{"Content-Type" : "application/json"}}).then(function(response) {
    //code here 
    if (response.data.length>0){
   alert ('Thank You for your Inquiry, You will get a response soon')
   msta.setState({email: '', mobno: '', mmessage: ''})
 }
      
    })
   .catch(function (error) {
    // handle error
    alert(error)
  })
  .then(function () {
    // always executed
  });
}
else{
  alert ('Invalid Entry')
}
  }


  render(){

return (
	<div class="w3-sans-serif polaroid1">
       <div >

<header class="w3-container ">
  <h2>OUR CONTACT INFORMATION</h2>

</header>

<div class="w3-container ">
  <p>Location: Westery Building, Off Muthithi Road </p>
  <p>Address: P.O.Box 63019 -00200, Nairobi , Kenya</p>
  <p>Mobile NO: +254 716796187</p>
  <p>Email : info@swyp.co.ke</p>
  </div>

<footer class="w3-container">
 
</footer>

</div> 

 <div>

<header class="w3-container ">
  <h2>Leave a Message</h2>

</header>

<div className="w3-container ">
        <Form>
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Email</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.email} type="email" onChange={this.handleemail}/>
             
          </InputGroup>
          <p className="w3-text-red">{this.state.vmessage}</p>
          </FormGroup>
          <FormGroup>
          <InputGroup>
            
           
             <PhoneInput
             placeholder="Phone Number"
             defaultCountry="KE"
            value={this.state.mobno}
            onChange={mobno => this.handlemobno(mobno)}
            className="mobno"
              />
             
          </InputGroup>
          <p className="w3-text-red">{this.state.vmessagemobno}</p>
          </FormGroup>
           
          <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Message</InputGroupText>
            </InputGroupAddon>
             <Input value={this.state.mmessage} type="text" onChange={this.handlemmessage}/>
          </InputGroup>
          </FormGroup>
        </Form>
        <p> Get in touch with us today. We are happy to answer any question</p>
  </div>

<footer class="w3-container">
<Button color="secondary"  className="pushable" onClick={this.handlesend}>

           Send
</Button>
</footer>

</div> 

<div id="mt">
</div>

</div> 
);
}
}
export default Contact; 	
