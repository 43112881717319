import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Nav from './nav.js'
import img1 from './img/panace1.png';
import img2 from './img/swyp1.png';
import logo from './img/logo.png';
import './css/my.css'

function Summary() {
  return (
    <Row className="sum w3-sans-serif">
      <Col xs="15" md="3" >
      <p>Convinient</p>
      <p >Works 24/7</p>
      </Col>
      <Col xs="15" md="3">
        <p>Easy to Use</p>
        <p>Time Saving</p>
        
        </Col>
        <Col xs="15" md="3">
          <p>Assured customer satisfaction</p>
        </Col>
        <Col xs="15" md="3">
          <p>Self service</p>
        </Col>
      </Row>
    
      
  );
}

export default Summary;