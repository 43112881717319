import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Marquee from "react-fast-marquee";
import Head from './components/head.js'
import Head1 from './components/head1.js'
import Demo from './components/demo.js'
import Rdemo from './components/rdemo'
import Con from './components/con.js'
import Content from './components/content.js'
import Footer from './components/footer.js'
import Tittle from './components/tittle.js'
import Summary from './components/summary.js'
import Dial from './components/dial.js'
import Mbody from './components/mbody.js'
import './App.css';
import './components/css/footer.css'
import './components/css/my.css'
import background from "./components/img/back1.png";


function App() {
  return (
    <div className="App">
      <div className="App-con">
        <Con />
      </div>
    
      <header  className="App-header heading">
      <Head />
      </header>
      <nav1 className="App-Nav" style={{ backgroundImage: `url(${background})` }}>
      <Head1 />
      <Tittle />
      
      <Rdemo/>
      <marquee className="Mar " scrollamount="5">Swyp directs M-Pesa payments to the nozzle selected  during payment thus enabling self-service sales</marquee>
      </nav1>
      <sum className="App-sum w3-margin" id="sum">
        <Summary />
      </sum>
      <div id="mbody" className="App-body">
      <Mbody />
      </div>
      <dial>
      <Dial />
      </dial>
      <footer className="App-footer ff">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
