import React from 'react';
import Contact2 from './contact2'
import ReactDOM from 'react-dom';
import Spin from './spin'

import './css/rdemo.css'
const handledemo = () => {  
     ReactDOM.render(
  <React.StrictMode>
  <Spin r={Contact2}/>
  </React.StrictMode>,
  document.getElementById('mbody')
);
    

     }
function Rdemo() {

  return (

<div class="stage2 r" onClick={e=>handledemo(e)}>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
  <div class="layer2"></div>
 
</div>

    
      
  );
}

export default Rdemo;
