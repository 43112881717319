import React from 'react';
import ReactDOM from 'react-dom';
import { SocialIcon } from 'react-social-icons';
import { BsTelephoneFill,BsEnvelopeFill } from "react-icons/bs";
import Foot from './Foot';
import Foot1 from './Foot1';
import Foot2 from './Foot2';
import './css/w3.css';
import './css/w3-2018.css';
import './css/bootstrap.min.css';
import './css/footer.css'


import {
  Container, Row, Col, Form, Input, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, CardImg, Header
} from 'reactstrap';

class Con extends React.Component {
constructor(props) {
    super(props);
     
    this.handleClick = this.handleClick.bind(this);  
   }

handleClick() { 
alert('ok');   
    

   }
render() {
    return (


     <Row className="w3-sans-serif sum ">
            <Col xs={"12"} md={"5"}>
            
             
            
  
          </Col>
         
          <Col xs={"12"} md={"3"}>

             <BsTelephoneFill />
             <c style={{ marginLeft: 10 }} style={{ marginLeft: 10 }}>+254 716796187</c>
          </Col>
          <Col xs={"12"} md={"3"} >
             
            <BsEnvelopeFill />
            <c style={{ marginLeft: 10 }}>info@swyp.co.ke</c>
            
  
          </Col>

          
        </Row>
   

);
}
}
export default Con;