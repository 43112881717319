import React, { useEffect,useState } from "react";
import { css } from "@emotion/react";
import ReactDOM from 'react-dom';
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Spin(props) {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  useEffect(() => {
    const timer = setTimeout(() => {
      ReactDOM.render(
  <React.StrictMode>
    <props.r />
  </React.StrictMode>,
  document.getElementById('mbody')
);

window.location.replace("/#sum");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div >
     

      <ClipLoader color={color} loading={loading} css={override} size={70} speedMultiplier= {2}/>
    </div>
  );
}

export default Spin;
